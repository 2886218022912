import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface UserState {
  loggedUser: {
    [key: string]: any;
  };
}

const initialState: UserState = {
  loggedUser: {},
};

export const getInitialState = createAction(
  'getInitialState',
  function prepare(tst: any) {
    return {
      payload: {
        initialState,
      },
    };
  }
);

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoggedUser: (state = initialState, action: any) => {
      void (state.loggedUser = action.payload);
    },
  },
});

export const selectBackground = (state: any) =>
  state.loggedUser.pageSetup.background;
export const { setLoggedUser } = userSlice.actions;
export default userSlice.reducer;

import en from './locales/en/en.json';
import de from './locales/de/de.json';
import es from './locales/es/es.json';
import sr from './locales/sr/sr.json';
import it from './locales/it/it.json';
import hr from './locales/hr/hr.json';
import bs from './locales/bs/bs.json';
import pt from './locales/pt/pt.json';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
export const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  sr: {
    translation: sr,
  },
  it: {
    translation: it,
  },
  hr: {
    translation: hr,
  },
  bs: {
    translation: bs,
  },
  pt: {
    translation: pt,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  nsSeparator: false,
  keySeparator: false,
  // do not load a fallback
  fallbackLng: 'en',
});

import api from '../api';

const UserService = {
  changeColors: async function (colors: any, locationID: string) {
    try {
      let resp = await api
        .patch(`/me/${locationID}/color`, colors)
        .then((res: any) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  changeImage: async function (file: any, locationID: string) {
    try {
      let resp = await api
        .patch(`/me/${locationID}/image`, { file })
        .then((res: any) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  changeLogo: async function (file: any, locationID: string) {
    try {
      let resp = await api
        .patch(`/me/${locationID}/logo`, { file })
        .then((res: any) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  changeText: async function (locationID: string, data: any) {
    try {
      let resp = await api
        .patch(`/me/${locationID}/text`, data)
        .then((res) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  getMyLocations: async function () {
    try {
      let resp = await api.get('/me/locations').then((res) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  getOneLocation: async function (locationID: string) {
    try {
      let resp = await api
        .get(`/me/locations/${locationID}`)
        .then((res) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  uploadFile: async function (data: any) {
    try {
      let resp = await api.post('/file', data).then((res: any) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  getDashboardInfo: async function (dates: any) {
    try {
      let params = {};
      if (dates) {
        params = {
          ...params,
          startDate: dates.dateFrom,
          endDate: dates.dateTo,
        };
      }
      let resp = await api
        .get('/me/dashboard', { params })
        .then((res) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  changeDateFormat: async function (format: string) {
    try {
      let resp = await api
        .patch('auth/me/dateformat', { dateFormat: format })
        .then((res) => res.data);
      return resp;
    } catch (error) {
      throw error;
    }
  },
};

export default UserService;

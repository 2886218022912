import './Spinner.scss'


type SpinnerProps = {
  size?: string
}
const Spinner = ({size}: SpinnerProps) => {

  if(!size) size = 'small'

  return (
    <div className={`loader ${size}`}>
    </div>
  )
}

export default Spinner
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// REDUX STORE
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import store from './store';

// const initialStore = {
//   loggedUser: {
//   },
// }

// const reducer = (state = initialStore, action: any) => {
//   switch (action.type) {

//     case "SET_LOGGED_USER":
//       return { ...state, loggedUser: action.user }
//   }

//   return state
// }

// const store = configureStore(reducer)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

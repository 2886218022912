// import { ReactComponent as LogoBlack } from '../../assets/clickreview_logo_black.svg'
import { ReactComponent as BurgerMenu } from '../../assets/burger-menu.svg';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard-gray.svg';
import { ReactComponent as DashboardIconColor } from '../../assets/dashboard-color.svg';
import './Header.scss';
import { useNavigate, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import headerArrowDownSVG from '../../assets/header-arrow-down.svg';
import UserService from '../../services/user.service';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Tooltip } from '@mui/material';
import { ReactComponent as WebsiteIcon } from '../../assets/edit-website-gray.svg';
import { ReactComponent as WebsiteIconColor } from '../../assets/edit-website-color.svg';
import { ReactComponent as StatisticsIcon } from '../../assets/statistics.svg';
import { ReactComponent as StatisticsIconColor } from '../../assets/statistics-color2.svg';
import { ReactComponent as ClientIcon } from '../../assets/client-gray.svg';
import { ReactComponent as ReviewsIcon } from '../../assets/reviews-gray.svg';
import { ReactComponent as ReviewsIconColor } from '../../assets/reviews-color.svg';
import { ReactComponent as DevicesIcon } from '../../assets/device-gray.svg';
import { ReactComponent as DevicesIconColor } from '../../assets/device.svg';
import { ReactComponent as LicencesIconColor } from '../../assets/licences-color.svg';
import { ReactComponent as LicencesIcon } from '../../assets/licences-gray.svg';
import { ReactComponent as SurveysIconColor } from '../../assets/surveys-color.svg';
import { ReactComponent as SurveysIcon } from '../../assets/surveys-gray.svg';
import { connect } from 'react-redux';
import store from '../../store';
import { useTranslation } from 'react-i18next';

interface StateProps {
  loggedUser: any;
}

interface HeaderProps {
  sidebar?: Boolean;
  selectedLocation?: any;
  setSelectedLocation?: any;
}

type Props = StateProps & HeaderProps;

const Header = ({
  sidebar,
  selectedLocation,
  setSelectedLocation,
  loggedUser,
}: Props) => {
  const navigate = useNavigate();
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const location = useLocation();
  const _userService = UserService;
  const [allLocations, setAllLocations] = useState<any>([]);
  const [currentLocationIndex, setCurrentLocationIndex] = useState<any>('0');
  const { t } = useTranslation('translation');
  const [user, setUser] = useState<any>({});
  store.subscribe(() => {
    setUser(store.getState().user.loggedUser);
  });

  useEffect(() => {
    (async () => {
      try {
        const allLocationsData = await _userService.getMyLocations();
        setAllLocations(allLocationsData.data);
        if (localStorage.getItem('location') !== null) {
          setCurrentLocationIndex(localStorage.getItem('location'));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user]);
  if (location.pathname === '/login') {
    return <></>;
  }
  return (
    <>
      <div className={`header-container ${sidebarOpened ? 'opened' : ''}`}>
        {/* <div className="language-select">
          <div className="svg-container">
            <div className="img">
              <EnglishFlag />
            </div>
            <p>EN</p>
          </div>
        </div> */}

        <div className={'sidebarAndLocation'}>
          {/* {allLocations.length > 0 && (
            <div className="locationDiv">
              <p>
                {selectedLocation?.name.length > 30
                  ? selectedLocation?.name.substr(0, 30) + '...'
                  : selectedLocation?.name}
              </p>
              <img className="headerArrow" src={headerArrowDownSVG} />

              <div className="dropdownLocation">
                {allLocations &&
                  allLocations.map((e: any, index: any) => {
                    return (
                      <button
                        onClick={() => {
                          localStorage.setItem('location', index.toString());
                          setSelectedLocation(e);
                        }}
                        key={e._id}
                      >
                        {e.name}
                      </button>
                    );
                  })}
              </div>
            </div>
          )} */}
          {/* <div className="navigation">
            <div
              onClick={() => navigate('/leads')}
              className={`headerItem ${
                location.pathname.startsWith('/leads') ? 'active' : ''
              }`}
            >
              <Tooltip title="Leads"/>
                <div className='iconHolder'>
                  <DashboardIcon />
                  <DashboardIconColor className='color' />
                </div>
              </Tooltip>
            </div>
          </div> */}

          <div className="headerItems icons">
            <div
              onClick={() => navigate('/dashboard')}
              className={`headerItem ${
                location.pathname.startsWith('/dashboard') ? 'active' : ''
              }`}
            >
              <div className="iconHolder">
                <DashboardIcon />
                <DashboardIconColor className="color" />
              </div>
              <p>{t('Dashboard')}</p>
            </div>

            <div
              onClick={() => navigate('/website')}
              className={`headerItem ${
                location.pathname.startsWith('/website') ? 'active' : ''
              }`}
            >
              <div className="iconHolder">
                <WebsiteIcon />
                <WebsiteIconColor className="color" />
              </div>
              <p>{t('Website')}</p>
            </div>

            <div
              onClick={() => navigate('/statistics')}
              className={`headerItem ${
                location.pathname.startsWith('/statistics') ? 'active' : ''
              }`}
            >
              <div className="iconHolder">
                <StatisticsIcon />
                <StatisticsIconColor className="color" />
              </div>
              <p>{t('Statistics')}</p>
            </div>
            <div
              onClick={() => navigate('/reviews')}
              className={`headerItem ${
                location.pathname.startsWith('/reviews') ? 'active' : ''
              }`}
            >
              <div className="iconHolder">
                <ReviewsIcon />
                <ReviewsIconColor className="color" />
              </div>
              <p>{t('Reviews')}</p>
            </div>
            <div
              onClick={() => navigate('/surveys')}
              className={`headerItem ${
                location.pathname.startsWith('/surveys') ? 'active' : ''
              }`}
            >
              <div className="iconHolder">
                <SurveysIcon />
                <SurveysIconColor className="color" />
              </div>
              <p>{t('Surveys')}</p>
            </div>
            {allLocations.length > 1 && (
              <div
                onClick={() => navigate('/licenses')}
                className={`headerItem ${
                  location.pathname.startsWith('/licenses') ? 'active' : ''
                }`}
              >
                <div className="iconHolder">
                  <LicencesIcon />
                  <LicencesIconColor className="color" />
                </div>
                <p>{t('Licenses')}</p>
              </div>
            )}

            <div
              onClick={() => navigate('/devices')}
              className={`headerItem ${
                location.pathname.startsWith('/devices') ? 'active' : ''
              }`}
            >
              <div className="iconHolder">
                <DevicesIcon />
                <DevicesIconColor className="color" />
              </div>
              <p>{t('Devices')}</p>
            </div>
          </div>

          <div className="clientName">
            <ClientIcon />
            <p className="name">{user?.firstName}</p>
          </div>

          <Logo
            className="mobile-logo"
            onClick={() => {
              setSidebarOpened(false);
              navigate('/dashboard');
            }}
          />
          {sidebar ? (
            <div className="burger-menu">
              <BurgerMenu
                onClick={() => setSidebarOpened(!sidebarOpened)}
                height="100%"
              />
            </div>
          ) : null}
        </div>
      </div>
      {sidebar ? (
        <Sidebar setSidebarOpened={setSidebarOpened} opened={sidebarOpened} />
      ) : null}
    </>
  );
};

export default connect(mapStateToProps)(Header);

function mapStateToProps(state: any) {
  return {
    loggedUser: state.loggedUser,
  };
}

import api from "../api";

const AuthService = {
  login: async function(email:string ,password: string){
    try {
      const resp  = await api.post('/auth/login', {email,password}).then((res:any) => res.data);
      api.defaults.headers.common['Authorization'] = resp.token;
      localStorage.setItem('token', resp.token)
      return resp.token
    } catch (error) {
      throw error
    }
   
  },
  getMyInfo: async function(){
    try {
      const resp = await api.get(`/auth/me`).then((res:any)=> res.data)
      return resp
    } catch (error) {
      throw error
    }
    
  },
  changePassword: async function(data: any){
    try {
      let resp = api.patch('/auth/me/password', data).then((res:any)=> res.data)
      return resp
    } catch (error) {
      throw error
    }
  }
}

export default AuthService
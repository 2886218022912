import './Sidebar.scss';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as EditWeb } from '../../assets/edit_web.svg';
import { ReactComponent as Links } from '../../assets/links.svg';
import { ReactComponent as Statistics } from '../../assets/statistics.svg';
import { ReactComponent as Settings } from '../../assets/settings.svg';
import { ReactComponent as SettingsColor } from '../../assets/settings-color.svg';
import { ReactComponent as Logout } from '../../assets/logout.svg';
import { ReactComponent as StatisticsIconColor } from '../../assets/statistics-color2.svg';

import { ReactComponent as DashboardIcon } from '../../assets/dashboard-gray.svg';
import { ReactComponent as DashboardIconColor } from '../../assets/dashboard-color.svg';

import { ReactComponent as ReviewsIcon } from '../../assets/reviews-gray.svg';
import { ReactComponent as ReviewsIconColor } from '../../assets/reviews-color.svg';

import { ReactComponent as WebsiteIcon } from '../../assets/edit-website-gray.svg';
import { ReactComponent as WebsiteIconColor } from '../../assets/edit-website-color.svg';

import { ReactComponent as DevicesIcon } from '../../assets/device-gray.svg';
import { ReactComponent as DevicesIconColor } from '../../assets/device.svg';

import { ReactComponent as LicencesIconColor } from '../../assets/licences-color.svg';
import { ReactComponent as LicencesIcon } from '../../assets/licences-gray.svg';

import { ReactComponent as SurveysIconColor } from '../../assets/surveys-color.svg';
import { ReactComponent as SurveysIcon } from '../../assets/surveys-gray.svg';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { setLoggedUser } from '../../reducers/userSlice';
import { useEffect, useState } from 'react';
import UserService from '../../services/user.service';
interface StateProps {
  loggedUser: any;
}

interface DispatchProps {
  setLoggedUser: (user: any) => void;
}

interface OwnProps {
  opened: boolean;
  setSidebarOpened: any;
}

type SidebarProps = StateProps & DispatchProps & OwnProps;

const Sidebar = (props: SidebarProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [allLocations, setAllLocations] = useState<any>([]);
  const _userService = UserService;

  // ----------------------
  // Edit website
  // Links
  // Manage Clients
  // Statistics
  // Account settings
  // Admin
  // Logout
  // ----------------------

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('location');
    dispatch(setLoggedUser({}));
    navigate('/login');
  };
  const { t } = useTranslation('translation');

  useEffect(() => {
    (async () => {
      try {
        const allLocationsData = await _userService.getMyLocations();
        setAllLocations(allLocationsData.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <div className={`sidebar ${props.opened ? 'opened' : ''}`}>
      <div className="navigation">
        <div
          className="navigate"
          onClick={() => {
            props.setSidebarOpened(false);
            navigate('/dashboard');
          }}
        >
          <div
            className={`img-container ${
              location.pathname.startsWith('/dashboard') ? 'active' : ''
            }`}
          >
            <DashboardIcon width="100%" />
            <DashboardIconColor width="100%" className="color" />
          </div>
          <p>{t('Dashboard')}</p>
        </div>
        <div
          className="navigate"
          onClick={() => {
            props.setSidebarOpened(false);
            navigate('/website');
          }}
        >
          <div
            className={`img-container ${
              location.pathname.startsWith('/website') ? 'active' : ''
            }`}
          >
            <WebsiteIcon width="100%" />
            <WebsiteIconColor className="color" width="100%" />
          </div>
          <p>{t('Website')}</p>
        </div>
        <div
          className="navigate"
          onClick={() => {
            props.setSidebarOpened(false);
            navigate('/statistics');
          }}
        >
          <div
            className={`img-container ${
              location.pathname.startsWith('/statistics') ? 'active' : ''
            }`}
          >
            <Statistics width="100%" />
            <StatisticsIconColor className="color" width="100%" />
          </div>
          <p>{t('Statistics')}</p>
        </div>
        <div
          className="navigate"
          onClick={() => {
            props.setSidebarOpened(false);
            navigate('/reviews');
          }}
        >
          <div
            className={`img-container ${
              location.pathname.startsWith('/reviews') ? 'active' : ''
            }`}
          >
            <ReviewsIcon width="100%" />
            <ReviewsIconColor className="color" width="100%" />
          </div>
          <p>{t('Reviews')}</p>
        </div>
        <div
          className="navigate"
          onClick={() => {
            props.setSidebarOpened(false);
            navigate('/surveys');
          }}
        >
          <div
            className={`img-container ${
              location.pathname.startsWith('/surveys') ? 'active' : ''
            }`}
          >
            <SurveysIcon width="100%" />
            <SurveysIconColor className="color" width="100%" />
          </div>
          <p>{t('Surveys')}</p>
        </div>
        {allLocations.length > 1 && (
          <div
            className="navigate"
            onClick={() => {
              props.setSidebarOpened(false);
              navigate('/licenses');
            }}
          >
            <div
              className={`img-container ${
                location.pathname.startsWith('/licenses') ? 'active' : ''
              }`}
            >
              <LicencesIcon width="100%" />
              <LicencesIconColor className="color" width="100%" />
            </div>
            <p>{t('Licenses')}</p>
          </div>
        )}
        <div
          className="navigate"
          onClick={() => {
            props.setSidebarOpened(false);
            navigate('/devices');
          }}
        >
          <div
            className={`img-container ${
              location.pathname.startsWith('/devices') ? 'active' : ''
            }`}
          >
            <DevicesIcon width="100%" />
            <DevicesIconColor className="color" width="100%" />
          </div>
          <p>{t('Devices')}</p>
        </div>
        <div
          className="navigate"
          onClick={() => {
            props.setSidebarOpened(false);
            navigate('/settings');
          }}
        >
          <div
            className={`img-container ${
              location.pathname.startsWith('/settings') ? 'active' : ''
            }`}
          >
            <Settings width="100%" />
            <SettingsColor width="100%" className="color" />
          </div>
          <p>{t('Settings')}</p>
        </div>
        <div
          className="navigate"
          onClick={() => {
            props.setSidebarOpened(false);
            logout();
          }}
        >
          <div className={`img-container`}>
            <Logout width="100%" />
          </div>
          <p>{t('Logout')}</p>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

function mapStateToProps(state: any) {
  return {
    loggedUser: state.loggedUser,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setLoggedUser: (user: any) => dispatch({ type: 'SET_LOGGED_USER', user }),
  };
}

import axios from "axios";
import { setupInterceptorsTo } from './interceptor';

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
      "Content-Type": "application/json",
    },
  })

api.interceptors.request.use(function(config) {
  const token = localStorage.getItem('token')
  if(config && config.headers && token){
    config.headers['Authorization'] = token
  }
  return config
})

api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if(error.response.status === 401){
    api.defaults.headers.common['Authorization'] = false
    localStorage.removeItem('token')
    let location = window.location.href.split('/')
    if(location[location.length - 1] !== 'login'){
      window.location.href = 'login'
    }
  }
  return Promise.reject(error)
})

export default api;

import { connect } from 'react-redux';
import './SidebarDesktop.scss';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ReactComponent as EditWeb } from '../../assets/edit_web.svg';
import { ReactComponent as Links } from '../../assets/links.svg';
import { ReactComponent as Statistics } from '../../assets/statistics.svg';
import { ReactComponent as Settings } from '../../assets/settings.svg';
import { ReactComponent as SettingsColor } from '../../assets/settings-color.svg';
import { ReactComponent as Logout } from '../../assets/logout.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setLoggedUser } from '../../reducers/userSlice';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SidebarDesktop = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('location');
    dispatch(setLoggedUser({}));
    navigate('/login');
  };
  const { t } = useTranslation('translation');

  if (location.pathname === '/login') {
    return <></>;
  }
  return (
    <>
      <div className="logo" onClick={() => navigate('/dashboard')}>
        <Logo className="logoTag" />
      </div>
      <div className="sidebar-desktop-container">
        <div className="navigation">
          <Tooltip placement="right-start" title={t('Settings').toString()}>
            <div
              className={`svgHolder ${
                location.pathname.startsWith('/settings') ? 'active' : ''
              }`}
              style={{ marginBottom: '20px' }}
            >
              <Link to="/settings" aria-label="Settings">
                <div className="iconHolder">
                  <Settings />
                  <SettingsColor className="color" />
                </div>
              </Link>
            </div>
          </Tooltip>
          <Tooltip placement="right-start" title={t('Logout').toString()}>
            <div className="svgHolder">
              <Link onClick={logout} to="/" aria-label="Logout">
                <Logout />
              </Link>
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarDesktop);

function mapStateToProps(state: any) {
  return {
    loggedUser: state.loggedUser,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setLoggedUser: (user: any) => dispatch({ type: 'SET_LOGGED_USER', user }),
  };
}

// import logo from './logo.svg';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'normalize.css';
import './App.scss';
import { connect } from 'react-redux';
import Spinner from '../src/components/Spinner/Spinner';
// import ProtectedRoute from './routes/ProtectedRoute/ProtectedRoute';
import AuthService from './services/auth.service';
import { useSelector, useDispatch } from 'react-redux';
import { setLoggedUser, getInitialState } from './reducers/userSlice';
import UserService from './services/user.service';
import Header from './components/Header/Header';
import SidebarDesktop from './components/SidebarDesktop/SidebarDesktop';
import { RootState } from './store';
import { ToastContainer } from 'react-toastify';
import store from './store';
import { TtyRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from './helpers/useLocalStorage';
const Login = lazy(() => import('./routes/Login/Login'));
const Dashboard = lazy(() => import('./routes/Dashboard/Dashboard'));
const Settings = lazy(() => import('./routes/AccountSettings/AccountSettings'));
const Statistics = lazy(() => import('./routes/Statistics/Statistics'));
const Website = lazy(() => import('./routes/Website/Website'));
const Licenses = lazy(() => import('./routes/Licenses/Licenses'));
const Redirect = lazy(() => import('./routes/RedirectPage/Redirect'));
const Devices = lazy(() => import('./routes/Devices/Devices'));
const Reviews = lazy(() => import('./routes/Reviews/Reviews'));
const SurveysStats = lazy(() => import('./routes/SurveysStats/SurveysStats'));
const OneSurveyStat = lazy(
  () => import('./routes/SurveysStats/OneSurveyStat/OneSurveyStat')
);

const Router = (props: any) => {
  // const [user, setUser] = useState(null)
  const _authService = AuthService;
  const _userService = UserService;
  const dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [user, setUser] = useState({});
  // const user = useSelector((state: RootState) => state.user);
  const [hasGrid, setHasGrid] = useState(false);
  const test = getInitialState('test').payload.initialState.loggedUser;
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    pageSetup: {
      background: '',
      backgroundLogo: '',
      backgroundButtons: '',
      colorButtons: '',
    },
  };

  const [storedValue, setStoredValue] = useLocalStorage('language', 'en');
  const { t, i18n } = useTranslation('translation');

  useEffect(() => {
    if (localStorage.getItem('language')) {
      i18n.changeLanguage(storedValue);
    }
  }, [storedValue]);

  store.subscribe(() => {
    setUser(store.getState().user.loggedUser);
  });

  useEffect(() => {
    console.log(selectedLocation, 'location--------');
  }, [selectedLocation]);

  useEffect(() => {
    if (JSON.stringify(user) === JSON.stringify({})) {
      setHasGrid(false);
    } else {
      setHasGrid(true);
    }
  }, [user]);

  useEffect(() => {
    let userToken = localStorage.getItem('token');
    if (userToken) {
      try {
        const getData = async () => {
          let userData = await _authService.getMyInfo();
          if (userData) {
            dispatch(setLoggedUser(userData));
          }
        };
        getData();
      } catch (error) {
        console.log(error);
      }
    }
  }, []);
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let allLocations = await _userService.getMyLocations();
  //       let index;
  //       if (allLocations) {
  //         if (allLocations.data.length > 0) {
  //           if (localStorage.getItem('location')) {
  //             index = localStorage.getItem('location') || '0';

  //             return setSelectedLocation(allLocations.data[parseInt(index)]);
  //           }
  //           setSelectedLocation(allLocations?.data[0]);
  //           localStorage.setItem('location', '0');
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, []);

  // if (user.loggedUser.toString() === initialState.toString()) {
  //   return (
  //     <main className="noLogin">
  //       <ToastContainer newestOnTop pauseOnFocusLoss={false} />
  //       <Suspense fallback={<Spinner size="small" />}>
  //         <Routes>
  //           <Route path="/login" element={<Login />} />
  //           <Route path="*" element={<Login />} />
  //         </Routes>
  //       </Suspense>
  //     </main>
  //   );
  // }

  return (
    <main className={`${hasGrid ? 'grid' : 'noGrid'}`}>
      <Header
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        sidebar={true}
      />
      <SidebarDesktop />
      <Suspense
        fallback={
          <div className="suspense-loader">
            <Spinner size="small" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/login"
            element={<Login setSelectedLocation={setSelectedLocation} />}
          />
          {/* <Route path='/dashboard' element={<Dashboard />} /> */}
          {/* <Route path='/links' element={<Links/>} /> */}

          <Route
            path="/dashboard"
            element={
              <Dashboard
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                locationID={selectedLocation?._id}
                user={user}
              />
            }
          />
          {/* <Route
            path="/links"
            element={
              <Links
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                location={selectedLocation}
                setLocation={setSelectedLocation}
              />
            }
          /> */}
          <Route path="/settings" element={<Settings user={user} />} />
          <Route path="/licenses" element={<Licenses user={user} />} />
          <Route path="/statistics" element={<Statistics user={user} />} />
          <Route path="/surveys">
            <Route index element={<SurveysStats />} />
            <Route path=":surveyId" element={<OneSurveyStat />} />
          </Route>
          <Route
            path="/reviews"
            element={
              <Reviews
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                locationID={selectedLocation?._id}
                user={user}
              />
            }
          />
          <Route
            path="/website"
            element={
              <Website
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                locationID={selectedLocation?._id}
              />
            }
          />
          <Route
            path="/devices"
            element={
              <Devices
              // selectedLocation={selectedLocation}
              // setSelectedLocation={setSelectedLocation}
              // locationID={selectedLocation?._id}
              />
            }
          />

          {/* PROTECTED ROUTE */}
          {/* <Route
          path="/about"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                !!user && user.permissions.some( permission => permission.name === 'dash.about' )
              }
            >
              <About />
            </ProtectedRoute>
          }
        /> */}

          {/* NOT FOUND ROUTE */}
          <Route path="*" element={<Redirect />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </main>
  );
};

export default connect(mapStateToProps)(Router);

function mapStateToProps(state: any) {
  return {
    loggedUser: state.loggedUser,
  };
}
